function i(e) {
  return new Promise((n) => {
    if (document.querySelector(e)) return n(document.querySelector(e));
    const r = new MutationObserver((t) => {
      document.querySelector(e) && (n(document.querySelector(e)), r.disconnect());
    });
    r.observe(document.body, { childList: !0, subtree: !0 });
  });
}
i(".animated-list").then((e) => {
  document.addEventListener("DOMContentLoaded", (n) => {
    document.querySelectorAll(".animated-list-item").forEach((t) => {
      gsap
        .timeline({ scrollTrigger: { trigger: t, start: "75% 65%", end: "90%" } })
        .to(t, { opacity: 1 })
        .set(t, { className: "animated-list-item flex gap-10 relative active" });
    });
  });
});
